:root {
  --borderRadius: 8px;
  /* --buttonBackground: #5fc19c; */
  --buttonGreenBackground: #5fc19c;
  --headerGreenColor: #5fc19c;
  --buttonBackground: #fff1fc;
  --tableHighlightBackground: #7effce52;
  --buttonTextColor: rgb(56, 56, 56);
  --windowBackground: rgba(54, 54, 54, 1);
  --textColor: rgba(54, 54, 54, 1);
  --linkColor: rgb(240, 240, 240);
  --linkDarkColor: rgb(49, 55, 127);
  /* --headerColor: #5fc19c; */
  --headerColor: #0f010c;
  --tableHeaderColor: #686868;
  --darkHeaderColor: #316451;
  --grad1Color: rgba(9, 9, 65, 0.99);
  --grad2Color: rgba(23, 23, 112,0.99);
  --grad3Color: rgba(64, 64, 155,0.99);
}

.menu-a {
  /*padding: 18px 7px;*/
  line-height: 24px;
  text-decoration: none;
  text-align: left;
  color: var(--linkColor);
  position: relative;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.menu-title {
  color: var(--linkColor);
}

a {
  padding: 4px 4px;
  line-height: 24px;
  text-decoration: none;
  text-align: left;
  color: var(--linkDarkColor);
  position: relative;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
input {
  padding: 5px;
  margin: 5px;
}
button {
  background-color: var(--buttonBackground);
  border: 0px solid rgb(77, 77, 77);
  border-radius: var(--borderRadius);
  padding: 10px;
  color: var(--buttonTextColor);
}

.dark {
  color: var(--headerGreenColor);
  text-decoration: underline;
}
.darker {
  /*color: var(--darkHeaderColor);*/
  text-decoration: underline;
}
.strong {
  font-weight: bolder;
}
.red {
  color: red;
}
.header {
  width: 100%;
  height: 3em;
  /*position: absolute;*/
  left: 0;
  top: 45px;
  z-index: 600;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 30%);
  display: flex;
  background: linear-gradient(
    90deg,
    var(--grad3Color) 0%,
    var(--grad2Color) 50%,
    var(--grad1Color) 100%
  );
}
button {
  background-color: var(--buttonBackground);
  border: 0px solid rgb(77, 77, 77);
  border-radius: var(--borderRadius);
  padding: 10px;
  color: var(--buttonTextColor);

  /* border: none; */
  /* outline: none; */
  /* color: #fff; */
  /* background: #111; */
  cursor: pointer;
  position: relative;
  /* z-index: 0; */
  /* border-radius: 10px; */
}

button:before {
  content: '';
  background: linear-gradient(45deg, #56ff80, #5fc19c, #96ffb0, #21ffc8, #55ff12);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

button:active {
  color: #000;
}

button:active:after {
  background: transparent;
}

button:hover:before {
  opacity: 1;
}

button:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* The container must be positioned relative: */
select {
  position: relative;
  color: var(--buttonBackground);
  line-height: 2.5rem;
  height: 2rem;
  background-color: var(--buttonBackground);
  border: 0px solid rgb(77, 77, 77);
  border-radius: var(--borderRadius);
  padding: 5px;
  color: var(--buttonTextColor);
  border-bottom: '1px dotted pink';
  min-width: 200px;
  text-align: center;
  justify-items: center;
  outline: none;
  scroll-behavior: smooth;
}

option {
  background-color: var(--buttonBackground);
  margin: 10px;
  border: none;
  outline: 0px;
}

.logo {
  width: 40%;
  padding-left: 1rem;
  top: 45px;
  display: flex;
}
.username {
  width: 20%;
  padding: 0rem;
  left: 0;
  top: 45px;
  display: flex;
}

.menu {
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 20px;
}
.menu-navigation li {
  display: inline-block;
  list-style: none;
  padding: 0;
  padding-right: 20px;
  list-style-type: disc;
}

.icon-1 {
  padding-bottom: 3px;
  width: 3vmin;
  vertical-align: middle;
}

/* header .row {
  width: auto;
  top: 0px;
  height: 70px;
  position: relative;
} */

footer {
  padding-bottom: 3rem;
  font-size: 1.5rem;
  line-height: 2.7rem;
  font-family: Calibri, Arial, Helvetica;
  color: #fff;
  background: #bee3ef;
  display: block;
}

.footer-main {
  /* background: rgb(0, 0, 0);  */
  line-height: 1.6rem;
  color: #fff;
  grid-template-columns: auto auto auto;
  display: grid;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    var(--grad1Color) 0%,
    var(--grad2Color) 50%,
    var(--grad3Color) 100%
  );
}

.footer-main .footer-logo {
  width: 250px;
  height: 73px;
  /*background: url(../Logotip_INFINITUS.png) no-repeat center;*/
  background-size: 250px 73px;
  margin: -0.6rem 0 2.1rem -0.3rem;
  display: block;
}

.footer-bottom {
  margin-top: 3rem;
  text-align: center;
}

footer a,
footer a:visited {
  color: #fff;
  text-decoration: none;
}

.col-four,
.col-1-3 {
  width: 33.33333%;
}

.grid-container {
  padding: 20px;
  font-size: 1rem;
  text-align: center;
}

.grid-item {
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 20px;

  font-size: 1rem;
  text-align: left;
}

.content {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.2rem;
}
.content-device {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.3rem;
}

.tableHeader {
  width: '5%';
  vertical-align: bottom;
  border-bottom: #b5b5b5 1px solid;
}

table {
  border-width: 0;
  width: 100%;
  max-width: 100%;
  color: var(--tableHeaderColor);
  text-align: center;
  height: fit-content;
  /* font-size: 16px; */
}
tr:hover {
  background-color: var(--tableHighlightBackground);
}

h1 {
  /* color: #0000FF; */
  color: var(--headerColor);
  font-family: Arial, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 100%;
  text-align: left;
  /* margin-left: auto; */
  /* margin-right: auto; */
}
h2 {
  /* color: #0000FF; */
  color: var(--headerColor);
  font-family: Arial, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: 100%;
  /* text-align: left; */
  /* margin-left: auto; */
  /* margin-right: auto; */
}
h3 {
  /* color: #0000FF; */
  color: var(--headerColor);
  font-family: Arial, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 100%;
  /* text-align: left; */
  /* margin-left: auto; */
  /* margin-right: auto; */
}

.settingsWindow {
  background-color: var(--windowBackground);
  border: 2px solid rgb(77, 77, 77);
  border-radius: var(--borderRadius);
  box-shadow: 15px 15px 15px rgba(20, 20, 20, 0.5);
  color: rgb(204, 204, 204);
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  z-index: 30;
  position: absolute;
  margin: auto;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 480px;
  min-width: 280px;

  background: linear-gradient(
    90deg,
    var(--windowBackground) 0%,
    var(--grad2Color) 50%,
    var(--grad3Color) 100%
  );
}

.deviceRow {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 1px var(--grad3Color) / 30%;
  overflow: hidden;
  word-wrap: break-word;
}

.sliderPacked {
  display: block;
  padding-bottom: 2rem;
  text-align: center;
}
.rc-slider {
  margin-top: 5px;
}

.leftSide {
  width: 20%;
  float: left;
  padding: 1px;
}

.rightSide {
  width: 73%;
  float: right;
  padding: 1px;
}

.infoSide {
  width: 23%;
  float: left;
  padding: 3px;
}
.sensorSide {
  width: 55%;
  float: left;
  padding: 3px;
}
.mapSide {
  width: 35%;
  float: right;
  padding: 0px;
}

.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.bold {
  font-weight: bold;
  color: var(--tableHeaderColor);
}

.width5 {
  width: 5%;
}
.width20 {
  width: 20%;
}
.width30 {
  width: 30%;
}
.width50 {
  width: 50%;
}
.width60 {
  width: 60%;
}
.width70 {
  width: 70%;
}

.sensor-main {
  grid-template-columns: auto;
  display: grid;
  min-height: 50rem;
  /* width: 100%; */
  /* background: linear-gradient(
    90deg,
    var(--grad1Color) 0%,
    var(--grad2Color) 50%,
    var(--grad3Color) 100%
  ); */
}

.graph-main {
  //grid-template-columns: auto auto auto auto;
  display: flex;
  min-height: 400px;
  height: 500px;
  width: 100%;
  /* width: 100%; */
  /* background: linear-gradient(
    90deg,
    var(--grad1Color) 0%,
    var(--grad2Color) 50%,
    var(--grad3Color) 100%
  ); */
}


.inlineDiv 
{
  display: inline-flex;
  align-items: center;
}

.embeddedcontainer 
{
  width: 100%;
  height: 56.1vmax;
  overflow: scroll;
}

iframe 
{
  border-width: 0px;
}