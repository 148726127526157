body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-weight: normal;
  line-height: 1;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
  display: block;
  font-size: calc(6px + 1vmin);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
